import { useState } from 'react';
import toast from 'react-hot-toast';
import { FaCopy } from 'react-icons/fa';

const CopyableLink = ({ link, expiry }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    setIsCopied(true);
    toast.success("Copied...")
    setTimeout(() => setIsCopied(false), 2000); // Reset the copied state after 2 seconds
  };

  return (
    <div className="flex items-center space-x-2">
      <a
        href={link}
        className="underline hover:text-blue-500 transition-colors"
        onClick={(e) => e.preventDefault()}
      >
        {link}
      </a>
      <button
        className={`p-1 rounded-md hover:bg-gray-200 transition-colors ${
          isCopied ? 'text-green-500' : 'text-gray-500'
        }`}
        onClick={handleCopy}
      >
        <FaCopy />
      </button>
      <span className="text-gray-500 text-sm">{expiry}</span>
    </div>
  );
};

export default CopyableLink;