import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { getFunctions, httpsCallable } from "firebase/functions";
// import { db } from "../firebase/config";
import toast from "react-hot-toast";
import SignupBg from "../Images/SignupBg.png";
import PayaplImg from "../Images/PayPal.png";
import logo from "../Images/LogoWithText.png";
import { getBrowserInformation } from "../utils/getBrowserInfo";
import { v4 as uuidv4 } from 'uuid';

const ENV = process.env.REACT_APP_ENV;

const CheckoutPaypal = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const browserInfo = getBrowserInformation();

  const {
    register,
    formState: { errors, isValid },
    watch,
    trigger
  } = useForm({ mode: "onChange" });

  const watchedFields = watch();
  const password = watch("password");

  const handlePaypalPayment = async () => {
    const isValid = await trigger();
    if (!isValid) return;

    setIsSubmitting(true);
    const userData = {
      email: watchedFields.email,
      firstName: watchedFields.firstName,
      lastName: watchedFields.lastName,
      displayName:  `${watchedFields.firstName} ${watchedFields.lastName}`,
      password: watchedFields.password,
      isPaypalCheckoutPath: true,
    };

    try {
      const functions = getFunctions();
      const createPaypalOrder = httpsCallable(
        functions,
        "createPaypalOrder"
      );

      const response = await createPaypalOrder({
        userData,
        currency: "EUR",
        browserInfo,
      });
      
      const url = response.data.url;
      window.open(url, "_self");
    } catch (err) {
      toast.error("Erreur lors de l'initialisation du paiement. Veuillez réessayer.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="min-h-screen h-fit w-full flex flex-col bg-[#00061E] bg-cover bg-no-repeat pt-6 items-center"
      style={{ backgroundImage: `url(${SignupBg})` }}
    >
      <div className="flex items-center gap-2">
        <img src={logo} width={140} height={130} alt="Logo" />
        {ENV === "staging" && (
          <div className="text-sm text-gray-500">{ENV}</div>
        )}
      </div>

      <div className="w-[90%] lg:w-[85%] py-4">
        <div className="flex flex-col items-center gap-10 min-w-[100%]">
          <div className="w-full md:w-[75%] lg:w-[45%] px-2 h-full">
            <div className="rounded-lg bg-[#ffffff12] pt-4 form-card">
              <div className="p-3 md:px-10 py-6">
               
                    <div className="text-start bg-slate-800 p-4 py-3 rounded-lg font-semibold text-sm md:text-lg text-gray-300 mb-4 flex justify-between">
                      <div>Formation Chatgpt Academy</div>
                      <div>{"4 x 275 €"}</div>
                    </div>

                    <form className="flex flex-col gap-6 mb-2 font-poppins">
                      <div className="grid grid-cols-2 gap-6">
                        <div>
                          <label htmlFor="prénom" className="block text-start text-sm text-gray-300 mb-1">
                            Prénom
                          </label>
                          <input
                            id="prénom"
                            type="text"
                            {...register("firstName", { required: "Le prénom est requis" })}
                            className="w-full focus:shadow-glow px-4 py-3 bg-[#1E293B] border border-[#1E293B] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
                            placeholder="Votre prénom"
                          />
                          {errors.firstName && (
                            <p className="text-red-500 text-sm mt-1">{errors.firstName.message}</p>
                          )}
                        </div>
                        <div>
                          <label htmlFor="nom" className="block text-start text-sm text-white mb-1">
                            Nom
                          </label>
                          <input
                            id="nom"
                            type="text"
                            {...register("lastName", { required: "Le nom est requis" })}
                            className="w-full focus:shadow-glow px-4 py-3 bg-[#1E293B] border border-[#1E293B] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
                            placeholder="Votre nom"
                          />
                          {errors.lastName && (
                            <p className="text-red-500 text-sm mt-1">{errors.lastName.message}</p>
                          )}
                        </div>
                      </div>

                      <div>
                        <label htmlFor="email" className="block text-start text-sm text-gray-300 mb-1">
                          Adresse mail
                        </label>
                        <input
                          id="email"
                          type="email"
                          {...register("email", {
                            required: "L'email est obligatoire",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Adresse email invalide"
                            }
                          })}
                          className="w-full focus:shadow-glow px-4 py-3 bg-[#1E293B] border border-[#1E293B] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
                          placeholder="email@domain.com"
                        />
                        {errors.email && (
                          <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
                        )}
                      </div>

                      <div>
                        <label htmlFor="password" className="block text-start text-sm text-gray-300 mb-1">
                          Mot de passe
                        </label>
                        <input
                          id="password"
                          type="password"
                          {...register("password", {
                            required: "Le mot de passe est obligatoire",
                            minLength: {
                              value: 6,
                              message: "Le mot de passe doit comporter au moins 6 caractères"
                            }
                          })}
                          className="w-full focus:shadow-glow px-4 py-3 bg-[#1E293B] border border-[#1E293B] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
                          placeholder="******"
                        />
                        {errors.password && (
                          <p className="text-red-500 text-sm mt-1">{errors.password.message}</p>
                        )}
                      </div>

                      <div>
                        <label htmlFor="confirmPassword" className="block text-start text-sm text-gray-300 mb-1">
                          Confirmer le mot de passe
                        </label>
                        <input
                          id="confirmPassword"
                          type="password"
                          {...register("confirmPassword", {
                            required: "La confirmation du mot de passe est obligatoire",
                            validate: (value) =>
                              value === password || "Les mots de passe ne correspondent pas"
                          })}
                          className="w-full focus:shadow-glow px-4 py-3 bg-[#1E293B] border border-[#1E293B] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
                          placeholder="******"
                        />
                        {errors.confirmPassword && (
                          <p className="text-red-500 text-sm mt-1">{errors.confirmPassword.message}</p>
                        )}
                      </div>

                      <div>
                        <label htmlFor="phone" className="block text-start text-sm text-gray-300 mb-1">
                          Numéro de téléphone (optionnel)
                        </label>
                        <input
                          id="phone"
                          type="tel"
                          {...register("phoneNumber")}
                          className="w-full focus:shadow-glow px-4 py-3 bg-[#1E293B] border border-[#1E293B] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
                          placeholder="+33 XX XX XX XX"
                        />
                      </div>

                      <div className="w-full rounded-lg text-white text-center mt-4">
                        <button
                          type="button"
                          onClick={handlePaypalPayment}
                          disabled={!isValid || isSubmitting}
                          className="flex items-center w-full h-[55px] disabled:opacity-50 disabled:cursor-not-allowed py-4 mb-2 rounded-lg bg-[#FFD401] justify-center text-center gap-2 cursor-pointer"
                        >
                          {isSubmitting ? (
                            <div className="flex justify-center items-center">
                              <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-[#003087]"></div>
                            </div>
                          ) : (
                            <img
                              src={PayaplImg}
                              className="h-[30px] w-[150px]"
                              alt="paypal-button"
                            />
                          )}
                        </button>
                      </div>
                    </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPaypal;