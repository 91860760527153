import DepositsTracking from "../pages/Deposits";
import UsersTracking from "../pages/UsersTracking";

const SalesRoutes = [
    {
      path: "/sales/user-tracking",
      exact: true,
      element: UsersTracking,
    },
    {
      path: "/sales/deposits",
      exact: true,
      element: DepositsTracking,
    }
  
  ]

  export default SalesRoutes;