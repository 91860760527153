import React from "react";

const StripeCheckoutSkeleton = () => {
  return (
    <>
      <div className="bg-[#ffffff12] py-5 rounded-lg animate-pulse">
        <div className="p-10 py-5">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <div className="h-4 bg-gray-500 rounded w-1/3 mb-4"></div>
              <div className="space-y-6">
                <div className="h-10 bg-gray-600 rounded"></div>
                <div className="h-10 bg-gray-600 rounded"></div>
                <div className="h-10 bg-gray-600 rounded"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="mt-2 text-center text-sm text-gray-600">
        <span className="h-4 bg-gray-500 rounded w-1/4 inline-block"></span>
      </p>
    </>
  );
};

export default StripeCheckoutSkeleton;
