import "./MyProfile.css";
import Header from "../Components/Header";
import EventCard from "../ProfileComponents/EventCard";
import CourseCard from "../ProfileComponents/CourseCard";
import ResumeTraining from "../ProfileComponents/ResumeTraining";
import DonutChart from "../ProfileComponents/DonutChart";
import UserInfo from "../ProfileComponents/UserInfo";
import useUserRole from "../hooks/useUserRole";
import { collection, doc, getDoc, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { db } from "../firebase/config";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGlobal } from "../context/GlobalContext";
import CoursesSectionSkeleton from "../Skeleton/CourseSectionSkeleton";
import UserInfoSkeleton from "../Skeleton/UserInfoSkeleton";
import DonutSectionSkeleton from "../Skeleton/DonutSectionSkeleton";
import EventSectionSkeleton from "../Skeleton/EventSectionSkeleton";
import ReactConfetti from "react-confetti";

export default function MyProfile({ setIsSidebarOpen, isSidebarOpen }) {
  const { sectionCompletionStatus } = useGlobal();
  const { user, pseudo } = useUserRole();
  const [sections, setSections] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [globalLoading, setGlobalLoading] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setGlobalLoading(true);
      
      // Set up sections listener
      const sectionsUnsubscribe = onSnapshot(
        collection(db, "sections"),
        (snapshot) => {
          const sectionsArray = snapshot.docs
            .map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }))
            .sort((a, b) => a.order - b.order);
          setSections(sectionsArray);
        },
        (error) => {
          console.error("Error getting sections data:", error);
        }
      );
     
      // Wait for initial data fetch
      await new Promise(resolve => setTimeout(resolve, 1000));
      setGlobalLoading(false);

      // Clean up listeners on unmount
      return () => {
        sectionsUnsubscribe();
      };
    };

    fetchData();
  }, []);

  useEffect(() => {
    let notificationsUnsubscribe = () => {};
    if (user?.uid) {
      const q = query(
        collection(db, "notifications"),
        where("userId", "==", user.uid)
      );
      notificationsUnsubscribe = onSnapshot(
        q,
        (snapshot) => {
          const notificationsArray = snapshot.docs
            .map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }))
            .sort((a, b) => b?.timestamp - a?.timestamp);
          setNotifications(notificationsArray);
        },
        (error) => {
          console.error("Error getting notifications data:", error);
        }
      );
    }
    return () => {
      notificationsUnsubscribe();
    };
  }, [user])

  useEffect(() => {
    const checkFirstLogin = async () => {
      if (user?.uid) {
        const userDocRef = doc(db, "users", user.uid);
        onSnapshot(userDocRef, (doc) => {
          if (doc.exists()) {
            const userData = doc.data();
            if (!userData.lastSignin) {
              console.log("No signin")
              // Show confetti if it's the first login
              setShowConfetti(true);
              // Update Firestore with the lastSignin timestamp
              setDoc(
                userDocRef,
                { lastSignin: new Date() },
                { merge: true }
              );
            }
          }
        });
      }
    };

    checkFirstLogin();
    
  }, [user]);
  if (globalLoading) {
    return (
      <div className="">
        
        <div className="w-full">
          <Header
            PageName={"Accueil"}
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        </div>
        <div className="flex gap-4 flex-col">
          <UserInfoSkeleton />
          <div className="grid grid-cols-1 gap-4 2xl:flex">
            <ResumeTraining />
            <DonutSectionSkeleton />
            <EventSectionSkeleton />
          </div>
          <CoursesSectionSkeleton />
        </div>
      </div>
    );
  }

  return (
    <div className="">
      {showConfetti && (
        <ReactConfetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          tweenDuration={8000}
          run={true}
          numberOfPieces={800}
        />
      )}
      <div className="w-full">
        <Header
          PageName={"Accueil"}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </div>

      <div className="flex gap-4 flex-col  overflow-y-auto scrollbar-thin scrollbar-thumb-blue-600 scrollbar-track-rounded scrollbar-thumb-rounded">
        <UserInfo userInfo={user} pseudo={pseudo} />
        <div className="grid grid-cols-1 gap-4 2xl:flex">
          <ResumeTraining />
          
          {sectionCompletionStatus && (
            <div className="2xl:w-[44%] 2xl:h-[380px]">
              <div className="hidden rounded-[15px] justify-evenly h-[380px] sm:flex flex-col sm:w-full gap-4 text-white border border-white/[0.3] bg-black/[0.65] p-[17px] px-[22px] bg-[#050E37]">
                <div className="header">
                  <h2 className="text-left font-semibold text-[20px]">
                    Mon niveau de formation
                  </h2>
                  <h3 className="text-[#a0aec0] text-[14px] text-left">
                    Le Pouvoir de l'IA n'attend que toi..
                  </h3>
                </div>
                <div className="pie-chart">
                  <div className="gap-[4px] rounded-[15px] flex text-white w-full overflow-x-auto no-scrollbar">
                    {sectionCompletionStatus?.map((section) => (
                      <div
                        key={section?.sectionId}
                        className="flex rounded p-2 gap-4 w-full flex-col items-center justify-center"
                      >
                        <DonutChart data={section?.completionPercentage} />
                        <div className="text-content">
                          <h2 className="text-white w-[100%] text-[11px]">
                            {section?.sectionName}
                          </h2>
                          <h3 className="text-[#A0AEC0] w-[100%] text-[11px]">
                            Ton niveau d'avancement pour cette formation
                          </h3>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              
              <h2 className="sm:hidden text-white my-2 mb-4 text-[16px] font-semibold text-left">
                Mes statistiques
              </h2>
              <div className="sm:hidden 2xl:h-[380px] rounded-[15px] flex gap-4 text-white w-full overflow-x-auto no-scrollbar">
                {sectionCompletionStatus?.map((section) => (
                  <div
                    key={section?.sectionId}
                    className="border border-white/[0.3] bg-black/[0.65] p-2 bg-[#050E37] rounded-[15px]"
                  >
                    <div className="flex rounded gap-4 w-full flex-col items-center justify-center">
                      <DonutChart data={section?.completionPercentage} />
                      <div className="text-content">
                        <h2 className="text-white w-[100%] text-[10px]">
                          {section?.sectionName}
                        </h2>
                        <h3 className="text-[#A0AEC0] w-[100%] text-[9px]">
                          Temps passé sur la plateforme
                        </h3>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="2xl:w-[28%]">
            <div className="flex items-center justify-between lg:hidden mb-4">
              <h2 className="text-[16px] font-semibold text-white">
                Nouveautés
              </h2>
              <span className="text-[12px] font-normal underline text-[#A0AEC0]">
                Voir tout
              </span>
            </div>
            <div className="flex w-full flex-col text-white gap-[30px] h-[380px] overflow-y-hidden rounded-[15px] bg-[#000000a6] border-[1px] border-[#ffffff4d] pt-[17px] pr-[22px] pb-0 pl-[22px]">
              <h2 className="hidden lg:block text-left text-[20px] text-white font-semibold">
                Nouveautés
              </h2>
              <div className="event-list flex flex-col gap-[15px] overflow-scroll pb-[15px]">
                {notifications?.map((notification) => (
                  <EventCard
                    notification={notification}
                    key={notification.id}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="flex p-[17px_22px] flex-col items-start gap-[30px] rounded-[15px] bg-[#000000a6] border-[1px] border-[#ffffff4d]">
          <div className="course-description text-white">
            <h2 className="text-[16px] md:text-[20px] font-semibold text-left">
              Mes Formations
            </h2>
            <h4 className="text-[14px] text-left text-[#a0aec0]">
              Le Pouvoir de l'IA n'attend que toi..
            </h4>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {sections?.map((section) => (
              <Link to={"/resume"} key={section?.id} className="h-full">
                <div className="h-full">
                  <CourseCard
                    tagText={section.levelName}
                    CardColor=""
                    buttonColor={
                      section.status === "completed"
                        ? "bg-gradient-to-r from-[#113BE1] via-[#4268FF] to-[#113BE1]"
                        : section.status === "inprogres"
                        ? "bg-custom-gradient"
                        : "bg-gradient-to-r from-[#113BE1] via-[#4268FF] to-[#113BE1]"
                    }
                    CourseHeading={section.title}
                    CourseDescription={section.description}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}