import { features } from "../../data/signupData";
import { Check } from "lucide-react";
import SignupImg from "../../Images/singup.png";

export const Benefits = ({ discount }) => (
  <div className="text-white  px-4">
    <div className="flex mx-auto justify-center mb-10">
      <img src={SignupImg} className="w-[400px]" />
    </div>
    <div>
      <h1 className="text-[20px] lg:text-[22px] text-center font-bold">
        Devenez un Expert en IA avec la ChatGPT Academy
      </h1>
      <p className="text-start text-xl lg:text-xl text-gray-300 my-6">
        Voici ce que l'Academy vous réserve :
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-4  justify-start">
        <div>
          {features.slice(0, 4).map((feature, index) => (
            <div
              key={index}
              className="w-full py-1 flex items-start justify-start text-sm text-white italic"
            >
              <div className="mt-1 bg-green-500 rounded-full p-1 mr-2">
                <Check className="w-4 h-4 text-black" />
              </div>
              <span className="text-start mt-1">{feature}</span>
            </div>
          ))}
        </div>
        <div>
          {features.slice(4, 8).map((feature, index) => (
            <div
              key={index + 4}
              className="w-full py-1 flex items-start justify-start text-sm text-white italic"
            >
              <div className="mt-1 bg-green-500 rounded-full p-1 mr-2">
                <Check className="w-4 h-4 text-black" />
              </div>
              <span className="text-start mt-1">{feature}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className="mt-8">
      <p className=" text-start text-xl">
        Accédez dès maintenant à l'Academy, maîtrisez l'IA grâce aux meilleurs
        outils et ressources, et rejoignez une communauté d'Acteurs IA !
      </p>
    </div>
  </div>
);
