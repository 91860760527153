import { createContext, useContext } from "react";
import { auth, db } from "../firebase/config";
import {
  serverTimestamp,
  collection,
  getDocs,
  addDoc,
  doc,
  setDoc,
  onSnapshot,
  deleteDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
} from "firebase/auth";

const FirebaseContext = createContext(null);

export const useFirebase = () => useContext(FirebaseContext);

// Login

const loginWithEmailAndPassword = async (email, password) => {
  await signInWithEmailAndPassword(auth, email, password);
  console.log("user is Login Successfully !");
};

// Signup
const createUserWithEmailAndPass = async (email, password, displayName) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    console.log("User signed up successfully:", user);

    await updateProfile(user, {
      displayName: displayName,
    });

    await setDoc(doc(db, "users", user.uid), {
      uid: user.uid,
      email: user.email,
      displayName: displayName,
    });
  } catch (error) {
    console.error("Error is Creating the User !", error);
  }
};

// course

const addData = async (collectionName, data) => {
  try {
    const docRef = await addDoc(collection(db, collectionName), {
      ...data,
      createdAt: serverTimestamp(),
    });
    console.log("Course added with ID: ", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error adding Course", error);
  }
};
// sectionData
const addSection = async (collectionName, data) => {
  try {
    await addDoc(collection(db, collectionName), {
      ...data,
      createdAt: serverTimestamp(),
    });
    return { status: "success", message: "Section added successfully" };
  } catch (error) {
    return { status: "fail", error: error };
  }
};

const fetchSections = async (collectionName) => {
  const sectionArray = [];
  try {
    onSnapshot(collection(db, collectionName), (snapshot) => {
      snapshot.docs.map((doc) => {
        const data = {
          ...doc.data(),
          id: doc.id,
        };
        sectionArray.push(data);
      });
    });
  } catch (error) {
    console.error("Error in getting section Data", error);
  }
  return sectionArray;
};
const updateSection = async (id, data) => {
  console.log(data, "data");

  const sectionRef = doc(db, "sections", id);
  try {
    await setDoc(
      sectionRef,
      {
        ...data,
      },
      { merge: true }
    );

    return { status: "success", message: "Section updated successfully.." };
  } catch (error) {
    return { status: "fail", message: error };
  }
};

const deleteSection = async (id) => {
  try {
    deleteDoc(doc(db, "sections", id)).then(() => {
      return { status: "success", message: "section deleted successfully..." };
    });
  } catch (error) {
    return { status: "fail", message: error };
  }
};

const updateModule = async (id, data) => {
  const updatedRef = doc(db, "modules", id);
  try {
    await updateDoc(updatedRef, {
      ...data,
    });
    return { status: "success", message: "Module updated successfully..." };
  } catch (error) {
    return { status: "fail", message: error };
  }
};
const addModule = async (data) => {
  try {
    await addDoc(collection(db, "modules"), {
      ...data,
      createdAt: serverTimestamp(),
    });

    return { status: "success", message: "Module added successfully" };
  } catch (error) {
    return { status: "fail", message: error };
  }
};

// deleteModule
const deleteModule = async (id) => {
  try {
    deleteDoc(doc(db, "modules", id)).then(() => {
      return { status: "success", message: "section deleted successfully..." };
    });
  } catch (error) {
    console.log("Error in deleting in Module", error);
  }
};

const fetchModules = async (collectionName) => {
  const modulesArray = [];
  try {
    onSnapshot(collection(db, collectionName), (snapshot) => {
      snapshot.docs.map((doc) => {
        const data = {
          ...doc.data(),
          id: doc.id,
        };
        modulesArray.push(data);
      });
    });
  } catch (error) {
    console.error("Error in getting modules Data", error);
  }
  return modulesArray;
};

//Add coupons

const addCoupon = async (data) => {
  try {
    await addDoc(collection(db, "coupons"), {
      ...data,
      createdAt: serverTimestamp(),
    });

    return { status: "success", message: "Coupon added successfully" };
  } catch (error) {
    return { status: "fail", message: error };
  }
};
const updateCoupon = async (id, data) => {
  const updatedRef = doc(db, "coupons", id);
  try {
    await updateDoc(updatedRef, {
      ...data,
    });
    return { status: "success", message: "Coupon updated successfully..." };
  } catch (error) {
    return { status: "fail", message: error };
  }
};
const deleteCoupon = async (id) => {
  try {
    deleteDoc(doc(db, "coupons", id)).then(() => {
      return { status: "success", message: "Coupon deleted successfully..." };
    });
  } catch (error) {
    console.log("Error in deleting in Coupon", error);
  }
};


//Signup Links
const addSignupLink = async (data) => {
  try {
    await setDoc(doc(db, "signupLinks", data.randomId), {
      ...data,
      createdAt: serverTimestamp(),

    })
    return { status: "success", message: "SignupLink added successfully" };
  } catch (error) {
    return { status: "fail", message: error };
  }
};
const updateSignupLink = async (id, data) => {
  const updatedRef = doc(db, "signupLinks", id);
  try {
    await updateDoc(updatedRef, {
      ...data,
    });
    return { status: "success", message: "SignupLink updated successfully..." };
  } catch (error) {
    return { status: "fail", message: error };
  }
};
const deleteSignupLink = async (id) => {
  try {
    deleteDoc(doc(db, "signupLinks", id)).then(() => {
      return { status: "success", message: "SignupLink deleted successfully..." };
    });
  } catch (error) {
    console.log("Error in deleting in SignupLink", error);
  }
};

// Rewards
const addRewards = async (collectionName, data) => {
  try {
    const docRef = await addDoc(collection(db, collectionName), {
      ...data,
      createdAt: serverTimestamp(),
    });
    console.log("Rewards is added with ID: ", docRef.id);
  } catch (error) {
    console.error("Error in added rewards");
  }
};

const addVideo = async (collectionName, data) => {
  try {
    await addDoc(collection(db, collectionName), {
      ...data,
      createdAt: serverTimestamp(),
    });
    return { status: "success", message: "Video added successfully..." };
  } catch (error) {
    console.error("Error adding in Video");
    return { status: "fail", message: error };
  }
};
const updateVideo = async (id, data) => {
  const videoRef = doc(db, "videos", id);
  try {
    await updateDoc(videoRef, {
      ...data,
    });
    return { status: "success", message: "Video updated successfully.." };
  } catch (error) {
    return { status: "fail", message: error };
  }
};

const deleteVideo = async (id) => {
  try {
    deleteDoc(doc(db, "videos", id)).then(() => {
      return { status: "success", message: "Video deleted successfully..." };
    });
  } catch (error) {
    return { status: "fail", message: error };
  }
};
// update reward
const updateReward = async (collectionName, id, data) => {
  const rewardRef = doc(db, collectionName, id);
  try {
    await updateDoc(rewardRef, {
      ...data,
    });
  } catch (error) {
    console.log("Error in updating the Reward", error);
  }
};
// delete reward
const deleteReward = async (collectionName, id) => {
  try {
    deleteDoc(doc(db, collectionName, id)).then(() => {
      return "Reward deleted succeddfully...";
    });
  } catch (error) {
    console.error("Error in Deleting the Reward", error);
  }
};
const getVideos = async () => {
  try {
    const videoCollection = collection(db, "videos");
    onSnapshot(videoCollection, (snapshot) => {
      const videoList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return videoList;
    });
  } catch (error) {
    console.error("Error getting video data", error);
  }
};

// quizData

const addQuiz = async (collectionName, data) => {
  try {
    const docRef = await addDoc(collection(db, collectionName), {
      ...data,
      createdAt: serverTimestamp(),
    });
    console.log("Quiz is Added with ID: ", docRef.id);
    return { status: "success", message: "Quiz added successfully..." };
  } catch (error) {
    console.error("Error adding in Quiz");
    return { status: "fail", message: error };
  }
};
const updateQuiz = async (id, data) => {
  const quizRef = doc(db, "quizes", id);
  try {
    await updateDoc(quizRef, {
      ...data,
    });
    return { status: "success", message: "Quiz updated successfully.." };
  } catch (error) {
    return { status: "fail", message: error };
  }
};

const deleteQuiz = async (id) => {
  try {
    deleteDoc(doc(db, "quizes", id)).then(() => {
      return { status: "success", message: "Quiz deleted successfully..." };
    });
  } catch (error) {
    return { status: "fail", message: error };
  }
};

// getCourseData
const getAllData = async (collectionName) => {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));
    const newArray = [];
    querySnapshot.forEach((doc) => {
      const data = {
        ...doc.data(),
        id: doc.id,
      };
      newArray.push(data);
    });
    return newArray;
  } catch (error) {
    console.log("Error getting documents", error);
    return [];
  }
};

//questions
const createQuestion = async (questionData) => {
  const questionsRef = collection(db, "questions");
  try {
    await addDoc(questionsRef, { ...questionData });
    return { status: "success", message: "Question added successfully.." };
  } catch (error) {
    return { status: "fail", message: error };
  }
};
const writeQuestion = async (questionId, questionData) => {
  const questionRef = doc(db, "questions", questionId);
  try {
    await updateDoc(questionRef, { ...questionData });
    return { status: "success", message: "Question updated successfully.." };
  } catch (error) {
    return { status: "fail", message: error };
  }
};

const removeQuestion = async (questionsId) => {
  const questionRef = doc(db, "questions", questionsId);
  try {
    await deleteDoc(questionRef);
    return { status: "success", message: "Question removed successfully.." };
  } catch (error) {
    return { status: "fail", message: error };
  }
};

//Quiz questions
const removeQuestionFromQuiz = async (quizId, questionId) => {
  try {
    const quizRef = doc(db, "quizes", quizId);
    await updateDoc(quizRef, {
      questions: arrayRemove(questionId),
    });
    return {
      status: "success",
      message: "Question removed from quiz successfully",
    };
  } catch (error) {
    console.error("Error removing question from quiz:", error);
    return { status: "error", message: error.message };
  }
};
const addQuestionsToQuiz = async (quizId, questions) => {
  try {
    const quizRef = doc(db, "quizes", quizId);
    await updateDoc(quizRef, {
      questions: arrayUnion(...questions),
    });
    return {
      status: "success",
      message: "Questions added to quiz successfully",
    };
  } catch (error) {
    console.error("Error adding questions to quiz:", error);
    return { status: "error", message: error.message };
  }
};

const getRewards = async (collectionName) => {
  try {
    const querySnapshot = onSnapshot(collection(db, collectionName));
    const rewardArray = [];
    querySnapshot.docs.map((doc) => {
      const data = {
        ...doc.data(),
        id: doc.id,
      };
      rewardArray.push(data);
    });
    return rewardArray;
  } catch (error) {
    console.error("Error is getting the Rewards Data", error);
    return [];
  }
};

export const FirebaseProvider = ({ children }) => {
  return (
    <FirebaseContext.Provider
      value={{
        addData,
        getAllData,
        addVideo,
        addSection,
        deleteSection,
        updateSection,
        addRewards,
        deleteQuiz,
        updateQuiz,
        addQuiz,
        removeQuestionFromQuiz,
        addQuestionsToQuiz,
        createQuestion,
        writeQuestion,
        removeQuestion,
        deleteVideo,
        updateVideo,
        getVideos,
        fetchModules,
        fetchSections,
        createUserWithEmailAndPass,
        loginWithEmailAndPassword,
        getRewards,
        deleteReward,
        updateReward,
        deleteModule,
        updateModule,
        addModule,
        deleteCoupon,
        updateCoupon,
        addCoupon,
        addSignupLink,
        updateSignupLink,
        deleteSignupLink
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
