import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import { useFirebase } from "../context/firebaseContext";
import {
  TrashIcon,
  PencilIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { db } from "../firebase/config";
import { collection, onSnapshot } from "firebase/firestore";
import Modal from "../Components/Modal";
import toast from "react-hot-toast";
import ManageUsersForm from "./ManageUsersForm";

const ManageUsers = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { deleteSignupLink } = useFirebase();
  const [currentRole, setCurrentRole] = useState("all"); // State for tabs

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);

  useEffect(() => {
    const userCollection = collection(db, "users");
    const unsubscribe = onSnapshot(userCollection, (snapshot) => {
      const userList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(userList);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const results = users.filter(
      (user) =>
        (currentRole === "all" || user.role === currentRole) &&
        user.displayName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(results);
    setCurrentPage(1); // Reset to first page when search results change
  }, [searchTerm, users, currentRole]);

  const handleAdd = () => {
    setSelectedUser(null);
    setIsModalOpen(true);
  };

  const handleUpdate = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      // await deleteSignupLink(id);
      toast.success("User deleted successfully...", {
        position: "top-center",
      });
    }
  };

  // Pagination logic
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const tabs = ["all", "admin", "manager", "sales"];

  return (
    <div className="w-full rounded-xl h-full">
      <div className="flex flex-col">
        <Header
          PageName={"Manage Users"}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </div>
      <div className="p-6 bg-[#060B26] rounded-xl border-[#ffffff55] border">
        <div className="w-full p-6 bg-[#060B26] rounded-xl">
          {/* Tabs */}
          <div className="flex mb-10 justify-between border-b border-gray-700">
            <div>
              {tabs.map((tab) => (
                <button
                  key={tab}
                  onClick={() => setCurrentRole(tab)}
                  className={`px-4 py-2 text-sm font-medium ${
                    currentRole === tab
                      ? "border-b-2 border-primaryBlue text-white"
                      : "text-gray-400 hover:text-white"
                  }`}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
            <div className="flex justify-between items-center gap-2 mb-4">
              <input
                type="text"
                placeholder="Search users..."
                className="px-4 py-2 border rounded-md bg-[#1C2536] text-white"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button
                onClick={handleAdd}
                className="px-4 py-2 bg-primaryBlue text-white rounded-md"
              >
                Add User
              </button>
            </div>
          </div>

          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-[#1c253600]">
              <tr>
                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                  Display Name
                </th>
                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                  Email
                </th>
                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                  Role
                </th>
                <th className="py-3 text-left text-[16px] font-normal text-gray-300 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-[#060B26] divide-y divide-gray-700">
              {currentUsers?.map((user) => (
                <tr key={user.id}>
                  <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                    {user.displayName}
                  </td>
                  <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                    {user.email}
                  </td>
                  <td className="text-left py-4 whitespace-nowrap text-sm text-white">
                    {user.role || "user"}
                  </td>
                  <td className="text-left py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => handleUpdate(user)}
                      className="text-indigo-600 hover:text-indigo-900 mr-2"
                    >
                      <PencilIcon className="h-5 w-5" />
                    </button>
                    <button
                      // onClick={() => handleDelete(user.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="mt-6 flex flex-col items-center">
            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-3 py-2 rounded-md bg-[#1C2536] text-white hover:bg-[#2C3546] disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronLeftIcon className="h-5 w-5" />
              </button>
              {[
                ...Array(Math.ceil(filteredUsers.length / usersPerPage)).keys(),
              ].map((number) => (
                <button
                  key={number + 1}
                  onClick={() => paginate(number + 1)}
                  className={`px-3 py-2 rounded-md text-sm font-medium ${
                    currentPage === number + 1
                      ? "bg-primaryBlue text-white"
                      : "bg-[#1C2536] text-white hover:bg-[#2C3546]"
                  }`}
                >
                  {number + 1}
                </button>
              ))}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={
                  currentPage === Math.ceil(filteredUsers.length / usersPerPage)
                }
                className="px-3 py-2 rounded-md bg-[#1C2536] text-white hover:bg-[#2C3546] disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronRightIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
            <ManageUsersForm
              selectedUser={selectedUser}
              onClose={handleCloseModal}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ManageUsers;
