import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where, Timestamp, onSnapshot, orderBy, getCountFromServer } from 'firebase/firestore';
import { db } from '../firebase/config';
import { Search, AlertCircle } from 'lucide-react';

// Updated StatCard to handle deadline information
const StatCard = ({ icon: Icon, title, value, iconColor = "bg-primaryBlue/20", textColor = "text-primaryBlue" }) => (
  <div className="bg-white/10 backdrop-blur-sm rounded-lg border border-borderColor p-6 hover:shadow-lg transition-shadow duration-200">
    <div className="flex items-center space-x-4">
      <div className={`p-3 rounded-full ${iconColor}`}>
        <Icon className={`w-6 h-6 ${textColor}`} />
      </div>
      <div>
        <p className="text-sm font-medium text-white/60">{title}</p>
        <h3 className="text-2xl text-left font-bold text-white">{value}</h3>
      </div>
    </div>
  </div>
);

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="flex items-center justify-center px-6 py-4 bg-[#071036] border-t border-borderColor">
    <button
      onClick={() => onPageChange(currentPage - 1)}
      disabled={currentPage === 1}
      className="px-4 py-2 text-xl font-medium text-white/60 disabled:opacity-50 disabled:cursor-not-allowed hover:text-white transition-colors"
    >
      &lt;
    </button>
    <span className="text-sm text-white/60">
      Page {currentPage} of {totalPages}
    </span>
    <button
      onClick={() => onPageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
      className="px-4 py-2 text-xl font-medium text-white/60 disabled:opacity-50 disabled:cursor-not-allowed hover:text-white transition-colors"
    >
      &gt;
    </button>
  </div>
);

// Calculate days remaining and deadline status
const calculateDeadlineInfo = (depositDate) => {
  if (!depositDate) return { daysRemaining: 0, isOverdue: false, deadline: null };
  
  const depositDateTime = depositDate.toDate();
  const deadline = new Date(depositDateTime.getTime() + (30 * 24 * 60 * 60 * 1000));
  const today = new Date();
  const diffTime = deadline - today;
  const daysRemaining = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  return {
    daysRemaining,
    isOverdue: daysRemaining < 0,
    deadline,
    depositDateTime
  };
};

const DepositsTracking = () => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalDeposits: 0,
    overdueAccounts: 0,
    activeDeposits: 0,
    completedPayments: 0,
  });
  const [deposits, setDeposits] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;

  useEffect(() => {
    let unsubscribe;
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        
        // Query only users who have paid a deposit
        const q = query(
          collection(db, "trialUsers"),
          where("status", "==", "success"),
          orderBy("createdAt", "desc")
        );

        unsubscribe = onSnapshot(q, (querySnapshot) => {
          const depositData = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            const { daysRemaining, isOverdue, deadline, depositDateTime } = calculateDeadlineInfo(data.createdAt);
            return {
              id: doc.id,
              name: data.displayName || 'Unknown',
              email: data.email || 'N/A',
              phoneNumber: data.phoneNumber || 'N/A',
              depositAmount: data.amount/100 || 0,
              remainingBalance: (99900 - data.amount) /100 || 0,
              depositDate: depositDateTime,
              deadline: deadline,
              daysRemaining,
              isOverdue,
              fullPaymentComplete: data.fullPaymentComplete || false,
            };
          });

          const totalDeposits = depositData.length;
          const overdueAccounts = depositData.filter(user => user.isOverdue).length;
          const activeDeposits = depositData.filter(user => !user.isOverdue && !user.fullPaymentComplete).length;
          const completedPayments = depositData.filter(user => user.fullPaymentComplete).length;

          setStats({
            totalDeposits,
            overdueAccounts,
            activeDeposits,
            completedPayments,
          });

          setDeposits(depositData);
          setLoading(false);
        });
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    
    fetchDashboardData();
    return () => unsubscribe?.();
  }, []);

  const filteredDeposits = deposits.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredDeposits.length / recordsPerPage);
  const startIndex = (currentPage - 1) * recordsPerPage;
  const paginatedDeposits = filteredDeposits.slice(startIndex, startIndex + recordsPerPage);

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  if (loading) {
    return (
      <div className="rounded-xl border-[#ffffff55] border h-screen flex justify-center items-center bg-primary p-6 font-poppins m-10">
        <div className="animate-spin w-16 h-16 border-4 border-primaryBlue border-t-transparent rounded-full" />
      </div>
    );
  }

  return (
    <div className="rounded-xl border-[#ffffff55] border bg-primary p-6 font-poppins m-10">
      <div className="max-w-7xl mx-auto space-y-6">
        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <StatCard 
            icon={(props) => (
              <svg {...props} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            )}
            title="Total Deposits"
            value={stats.totalDeposits}
            iconColor="bg-purple-500/20"
            textColor="text-purple-500"
          />
          <StatCard 
            icon={AlertCircle}
            title="Overdue Accounts"
            value={stats.overdueAccounts}
            iconColor="bg-red-500/20"
            textColor="text-red-500"
          />
          <StatCard 
            icon={(props) => (
              <svg {...props} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            )}
            title="Active Deposits"
            value={stats.activeDeposits}
            iconColor="bg-yellow-500/20"
            textColor="text-yellow-500"
          />
          <StatCard 
            icon={(props) => (
              <svg {...props} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            )}
            title="Completed Payments"
            value={stats.completedPayments}
            iconColor="bg-green-500/20"
            textColor="text-green-500"
          />
        </div>

        {/* Deposits Table */}
        <div className="bg-[#071036] rounded-lg border border-borderColor overflow-hidden">
          <div className="p-6 border-b header-container border-borderColor">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold text-white">Deposit Details</h2>
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setCurrentPage(1);
                  }}
                  className="pl-10 pr-4 py-2 bg-white/5 border border-borderColor rounded-lg text-white placeholder-white/40 focus:outline-none focus:ring-2 focus:ring-primaryBlue/50"
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white/40 w-4 h-4" />
              </div>
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-white/5">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                    Deposit Amount
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                    Remaining Balance
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                    Deposit Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                    Payment Deadline
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                    Days Remaining
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-borderColor">
                {paginatedDeposits.map(user => (
                  <tr key={user.id} className="hover:bg-white/5">
                   
                    <td className="px-6 py-4 text-left whitespace-nowrap">
                      <div className="text-sm text-white/60">{user.email}</div>
                    </td>
                    <td className="px-6 py-4 text-left whitespace-nowrap">
                      <div className="text-sm text-white/60">
                      € {user.depositAmount.toFixed(2)}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-left whitespace-nowrap">
                      <div className="text-sm text-white/60">
                      € {user.remainingBalance.toFixed(2)}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-left whitespace-nowrap">
                      <div className="text-sm text-white/60">
                        {user.depositDate ? user.depositDate.toLocaleDateString() : 'N/A'}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-left whitespace-nowrap">
                      <div className="text-sm text-white/60">
                        {user.deadline ? user.deadline.toLocaleDateString() : 'N/A'}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-left whitespace-nowrap">
                      <div className={`text-sm font-medium ${
                        user.fullPaymentComplete ? 'text-green-500' :
                        user.isOverdue ? 'text-red-500' :
                        user.daysRemaining <= 7 ? 'text-yellow-500' :
                        'text-white/60'
                      }`}>
                        {user.fullPaymentComplete ? 'Completed' :
                         user.isOverdue ? `${Math.abs(user.daysRemaining)} days overdue` :
                         `${user.daysRemaining} days remaining`}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-left whitespace-nowrap">
                      <div className={`text-sm font-medium ${
                        user.fullPaymentComplete ? 'text-green-500' :
                        user.isOverdue ? 'text-red-500' :
                        'text-yellow-500'
                      }`}>
                        {user.fullPaymentComplete ? 'Completed' :
                         user.isOverdue ? 'Overdue' :
                         'Pending'}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination 
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default DepositsTracking;