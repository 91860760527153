import React, { useState, useEffect } from "react";
import { useFirebase } from "../context/firebaseContext";
import toast from "react-hot-toast";
import DateTimePicker from "react-datetime-picker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { Calendar, X } from "lucide-react";
import { v4 as uuidv4 } from "uuid";
const ENV = process.env.REACT_APP_ENV;
const PrivateSignupLinksForm = ({ singupLink, onClose }) => {
  const [formData, setFormData] = useState({
    title: "",
    discountType: "percentage",
    value: null,
    actualPrice: null,
    totalPrice: null,
    expiry: null,
    createdAt: null,
    privateUrl: "",
    randomId: "",
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { updateSignupLink, addSignupLink } = useFirebase();
  useEffect(() => {
    // Generate URL with random ID
    const randomId = uuidv4().slice(0, 20);
    // Generate a 20-character random ID
    const baseUrl =
      ENV === "staging"
        ? "https://staging-chatgpt-academy-8b1b4.web.app/signup"
        : "https://training.chatgpt-academy.fr/signup";
    const privateUrl = `${baseUrl}/${randomId}`;

    if (singupLink) {
      setFormData({
        title: singupLink.title || "",
        discountType: singupLink.discountType || "percentage",
        value: +singupLink.value,
        actualPrice: +singupLink.actualPrice,
        totalPrice: +singupLink.totalPrice,
        expiry: new Date(singupLink.expiry.toDate()),
        createdAt: new Date(singupLink.createdAt.toDate()),
        privateUrl: singupLink.privateUrl || privateUrl,
        randomId: singupLink.randomId,
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        privateUrl,
        randomId,
      }));
    }
  }, []);
  const calculateTotalPrice = (data) => {
    const { discountType, value, actualPrice } = data;
    if (!actualPrice || !value) return;

    let totalPrice = 0;

    if (discountType === "percentage") {
      totalPrice = parseFloat(Math.floor(actualPrice - (actualPrice * value) / 100)).toFixed(2);
    } else if (discountType === "fixed") {
      totalPrice = parseFloat(actualPrice - value).toFixed(2);
    }

    totalPrice = totalPrice > 0 ? totalPrice : 0;
    setFormData((prevData) => ({
      ...prevData,
      totalPrice,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
    if (name === "value" || name === "actualPrice" || name === "discountType") {
      calculateTotalPrice({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.title) newErrors.title = "Title is required";
    if (!formData.discountType)
      newErrors.discountType = "Discount Type is required";
    if (!formData.value) newErrors.value = "Value is required";
    if (!formData.actualPrice)
      newErrors.actualPrice = "Actual price is required";
    if (!formData.expiry) newErrors.expiry = "Expiry is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleDateChange = (date) => {
    setFormData((prev) => ({
      ...prev,
      expiry: new Date(date),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      console.log("Form not validated...");
      return;
    }

    setIsLoading(true);
    try {
      const singupLinkData = {
        title: formData.title,
        discountType: formData.discountType,
        value: +formData.value,
        actualPrice: +formData.actualPrice,
        totalPrice: +formData.totalPrice,
        expiry: formData.expiry,
        privateUrl: formData.privateUrl,
        randomId: formData.randomId,
      };

      if (singupLink) {
        const res = await updateSignupLink(singupLink.id, singupLinkData);
        res.status === "success"
          ? toast.success(res.message, {
              position: "top-center",
            })
          : toast.error(res.message, {
              position: "top-center",
            });
      } else {
        if (formData.discountType === "percentage" && formData.value > 100) {
          setErrors({ value: "Percentage cannot be greater than 100" });
          return;
        }
        const res = await addSignupLink(singupLinkData);
        res.status === "success"
          ? toast.success(res.message, {
              position: "top-center",
            })
          : toast.error(res.message, {
              position: "top-center",
            });
      }
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(error.message, {
        position: "top-center",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="p-6 bg-[#060B26] rounded-xl border-[#ffffff29] border text-white"
    >
      <h2 className="text-xl text-start font-medium py-4 border-b mb-6">
        {singupLink ? "Update SingupLink" : "Add SingupLink"}
      </h2>

      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="title" className="block text-sm font-medium mb-1">
          Title
        </label>
        <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {errors.title && (
          <p className="mt-1 text-sm text-red-500">{errors.title}</p>
        )}
      </div>
      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="actualPrice" className="block text-sm font-medium mb-1">
          Actual Price
        </label>
        <input
          type="number"
          id="actualPrice"
          name="actualPrice"
          value={formData.actualPrice}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {errors.actualPrice && (
          <p className="mt-1 text-sm text-red-500">{errors.actualPrice}</p>
        )}
      </div>
      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="discountType" className="block text-sm font-medium">
          Discount Type
        </label>
        <select
          id="discountType"
          name="discountType"
          value={formData.discountType}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="percentage">Percentage</option>
          {/* <option value="fixed">Fixed</option> */}
        </select>
      </div>

      <div className="mb-4 flex-col items-start">
        <label
          htmlFor="value"
          className="block text-start text-sm font-medium mb-1"
        >
          Value
        </label>
        <input
          type="number"
          id="value"
          name="value"
          value={formData.value}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        {errors.value && (
          <p className="mt-1 text-sm text-red-500">{errors.value}</p>
        )}
      </div>
      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="totalPrice" className="block text-sm font-medium mb-1">
          Total Price After Discount
        </label>
        <input
          type="number"
          id="totalPrice"
          name="totalPrice"
          value={formData.totalPrice || 0}
          readOnly
          className="w-full p-2 text-start text-white bg-[#1C2536] h-full rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4 flex-col items-start">
        <label
          htmlFor="expiry"
          className="block text-start text-sm font-medium mb-1"
        >
          Expiry Date
        </label>

        <DateTimePicker
          value={formData.expiry}
          id="expiry"
          name="expiry"
          minDate={new Date()}
          calendarIcon={<Calendar />}
          clearIcon={<X />}
          selected={formData.expiry}
          calendarProps={{ className: "bg-[#000000]" }}
          onChange={handleDateChange}
          className="w-full p-2 text-start text-white bg-[#1C2536] h-full rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        {errors.expiry && (
          <p className="mt-1 text-sm text-red-500">{errors.expiry}</p>
        )}
      </div>
      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="privateUrl" className="block text-sm font-medium mb-1">
          Private URL
        </label>
        <input
          type="text"
          id="privateUrl"
          name="privateUrl"
          value={formData.privateUrl}
          readOnly
          className="w-full p-2 text-start text-white bg-[#1C2536] h-full rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isLoading}
          className={`px-4 py-2 bg-primaryBlue text-white rounded ${
            isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-600"
          }`}
        >
          {isLoading
            ? "Processing..."
            : singupLink
            ? "Update SingupLink"
            : "Add SingupLink"}
        </button>
      </div>
    </form>
  );
};

export default PrivateSignupLinksForm;
