export const appearance = {
  theme: "night",
  variables: {
    fontFamily: "Poppins, sans-serif",
    colorPrimary: "#3B82F6",
    colorDanger: "#ef4444",
    borderRadius: "8px",
  },
  rules: {
    ".Input": {
      border: "1px solid #FFFFFF3A",
      padding: "12px 16px",
      width: "100px",

    },
  
    ".Label": {
      fontSize: "14px",
      marginBottom: "4px",
      width: "900px",
      color: "#fff"
    },
    ".Tab": {
      padding: "8px 10px",
      border: "1px solid #FFFFFF1A",
      display: 'none'
    },

  },
};

export const checkoutAppearance =  {
  theme: "night",
  variables: {
    fontFamily: "Poppins, sans-serif",
    colorPrimary: "#3B82F6",
    colorDanger: "#ef4444",
    borderRadius: "8px",
  },
  rules: {
    ".Input": {
      border: "1px solid #FFFFFF3A",
      padding: "12px 14px",
      width: "100px",
      marginBottom: "8px"
    },
  
    ".Label": {
      fontSize: "14px",
      marginBottom: "2px",
      width: "900px",
      color: "#fff",
    },
    ".Tab": {
      padding: "8px 10px",
      border: "1px solid #FFFFFF1A",
      display: 'none',
      marginBottom: "4px"
    },

  },
};
export const features = [
  "Accès illimité à la ChatGPT Academy 🚀",
  `Accès à vie à toutes les ressources de formation IA actualisées (vidéos, fiches, automatisations, communauté, etc.)`,
  "Un accompagnement régulier et personnalisé avec nos experts",
  "Plus de 40 heures de contenu vidéo, régulièrement mises à jour",
  "Des quiz interactifs et des récompenses exclusives",
  "Une communauté dynamique pour échanger",
  "Une méthode pas-à-pas pour maîtriser l'IA",
  "Des ressources prêtes à l'emploi pour vos projets",
];