import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { getFunctions, httpsCallable } from "firebase/functions";
const ManageUsersForm = ({ selectedUser, onClose }) => {
  const functions = getFunctions();
  const [formData, setFormData] = useState({
    displayName: "",
    password: "",
    email: "",
    role: "",
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    // Generate URL with random ID

    if (selectedUser) {
      setFormData({
        displayName: selectedUser.displayName,
        email: selectedUser.email,
        role: selectedUser.role,
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.displayName)
      newErrors.displayName = "displayName Type is required";
    if (!formData.password) newErrors.password = "Password is required";
    if (!formData.role) newErrors.role = "Role is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      console.log("Form not validated...");
      return;
    }
    setIsLoading(true);

    try {
      const manageUsers = httpsCallable(functions, "manageUsers");

      const result = await manageUsers({
        displayName: formData.displayName,
        email: formData.email,
        password: formData.password,
        role: formData.role,
      });

      console.log("Response: ", result.data)
      toast.success("User registered successfully...")
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(error.message, {
        position: "top-center",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="p-6 bg-[#060B26] rounded-xl border-[#ffffff29] border text-white"
    >
      <h2 className="text-xl text-start font-medium py-4 border-b mb-6">
        {selectedUser ? "Update User" : "Add User"}
      </h2>

      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="displayName" className="block text-sm font-medium mb-1">
          DisplayName
        </label>
        <input
          type="text"
          id="displayName"
          name="displayName"
          required
          value={formData.displayName}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {errors.displayName && (
          <p className="mt-1 text-sm text-red-500">{errors.displayName}</p>
        )}
      </div>
      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="email" className="block text-sm font-medium mb-1">
          Email
        </label>
        <input
          type="email"
          id="email"
          required
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {errors.email && (
          <p className="mt-1 text-sm text-red-500">{errors.email}</p>
        )}
      </div>
      <div className="mb-4 flex flex-col items-start">
        <label htmlFor="role" className="block text-sm font-medium">
          Discount Type
        </label>
        <select
          id="role"
          name="role"
          required
          value={formData.role}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="admin">Admin</option>
          <option value="manager">Manager</option>
          <option value="sales">Sales Team</option>
          <option value="user">User</option>
        </select>
        {errors.role && (
          <p className="mt-1 text-sm text-red-500">{errors.role}</p>
        )}
      </div>

      <div className="mb-4 flex-col items-start">
        <label
          htmlFor="password"
          className="block text-start text-sm font-medium mb-1"
        >
          Password
        </label>
        <input
          type="text"
          id="password"
          name="password"
          password={formData.password}
          onChange={handleChange}
          className="w-full p-2 bg-[#1C2536] rounded border border-[#ffffff1a] focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        {errors.password && (
          <p className="mt-1 text-sm text-red-500">{errors.password}</p>
        )}
      </div>
      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isLoading}
          className={`px-4 py-2 bg-primaryBlue text-white rounded ${
            isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-600"
          }`}
        >
          {isLoading
            ? "Processing..."
            : selectedUser
            ? "Update User"
            : "Add User"}
        </button>
      </div>
    </form>
  );
};

export default ManageUsersForm;
