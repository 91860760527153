export function getBrowserInformation() {
    const browserInfo = {
        userAgent: navigator.userAgent,          // Browser user agent string
        platform: navigator.platform,           // Operating system
        language: navigator.language,           // Browser language
        online: navigator.onLine? "true" : "false",               // Whether the browser is online
        screenWidth: (window.screen.width)?.toString(),              // Screen width
        screenHeight: window.screen.height?.toString(),            // Screen height
    };

    return browserInfo;
}