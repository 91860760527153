export const calculateTimeLeft = (expiryDate) => {
if(!expiryDate) return null
  const now = new Date();
  const expiry = new Date(expiryDate.toDate());
  const difference = expiry - now;

  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  } else {
    return { expired: true };
  }
};
