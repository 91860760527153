const PMRadioSkeleton = () => {
    return (
      <div className="my-4 flex gap-20 justify-center items-center">
        {/* Stripe payment option skeleton */}
        <div className="flex items-center space-x-2 p-3 rounded-lg">
          <div className="w-5 h-5 rounded-full bg-gray-200 animate-pulse" />
          <div className="w-32 h-6 bg-gray-200 rounded animate-pulse" />
        </div>
        {/* PayPal payment option skeleton */}
        <div className="flex items-center space-x-2 p-3 rounded-lg">
          <div className="w-5 h-5 rounded-full bg-gray-200 animate-pulse" />
          <div className="w-24 h-6 bg-gray-200 rounded animate-pulse" />
        </div>
      </div>
    );
  };
  
  export default PMRadioSkeleton;