import React, { useState, useEffect } from "react";
import { calculateTimeLeft } from "../utils/calculateTimeLeft";

const ExpiryTimer = ({ expiryDate }) => {
 

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(expiryDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(expiryDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [expiryDate]);

  return (
    <div className="rounded-lg flex justify-center items-center price-card py-4 mb-4  text-4xl bg-[#ffffff12]">
      {timeLeft.expired ? (
        <div className="flex gap-10 w-full justify-center text-red-500">
          <div className="flex flex-col  text-center ">
            <span className="font-bold text-2xl">0</span>
            <span className="text-lg">Days</span>
          </div>
          :
          <div className="flex flex-col  text-center ">
            <span className="font-bold text-2xl">00</span>
            <span className="text-lg">Hours</span>
          </div>
          :
          <div className="flex flex-col  text-center ">
            <span className="font-bold text-2xl">00</span>
            <span className="text-lg">Minutes</span>
          </div>
          :
          <div className="flex flex-col  text-center ">
            <span className="font-bold text-2xl">00</span>
            <span className="text-lg">Seconds</span>
          </div>
        </div>
      ) : (
        <div className="flex gap-10 w-full  justify-center text-[#ffffff]">
          <div className="flex flex-col  text-center ">
            <span className="font-bold text-2xl">{timeLeft.days}</span>
            <span className="text-lg">Days</span>
          </div>
          :
          <div className="flex flex-col  text-center ">
            <span className="font-bold text-2xl">{timeLeft.hours}</span>
            <span className="text-lg">Hours</span>
          </div>
          :
          <div className="flex flex-col  text-center ">
            <span className="font-bold text-2xl">{timeLeft.minutes}</span>
            <span className="text-lg">Minutes</span>
          </div>
          :
          <div className="flex flex-col  text-center ">
            <span className="font-bold text-2xl">{timeLeft.seconds}</span>
            <span className="text-lg">Seconds</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpiryTimer;
