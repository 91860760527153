import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import LogoText from "../Images/TextLogo.svg";
import LogoImg from "../Images/Logo.png";
import NavUserInfo from "../ProfileComponents/NavUserInfo";
import DocumentationCard from "../ProfileComponents/DocumentationCard";
import { SvgIcon } from "./SvgIcon";
import useUserRole from "../hooks/useUserRole";
import { getIdTokenResult, onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase/config";
import SidebarSkeleton from "../Skeleton/SidebarSkeleton";

const userSideBar = [
  { to: "/", text: "Accueil", icon: "home" },
  { to: "/resume", text: "Reprendre la formation", icon: "resume" },
  { to: "/ranking", text: "Classement", icon: "ranking" },
  { to: "/reward", text: "Mes récompenses", icon: "reward" },
  { to: "/settings", text: "Paramètres", icon: "settings" },
];

const adminSidebar = [
  { to: "/admin/home", text: "Home", icon: "home" },
  { to: "/admin/user-stats", text: "Users Stats", icon: "users" },
  { to: "/admin/manage-users", text: "Manage Users", icon: "users" },
  { to: "/admin/user-tracking", text: "Track User", icon: "trackUser" },
  { to: "/admin/finance", text: "Finance", icon: "finance" },
  { to: "/admin/deposits", text: "Deposits", icon: "finance" },
  { to: "/admin/coupons", text: "Coupons", icon: "coupon" },
  { to: "/admin/signup-links", text: "Private links", icon: "signupLink" },
  { to: "/admin/sections", text: "Sections", icon: "section" },
  { to: "/admin/modules", text: "Modules", icon: "module" },
  { to: "/admin/videos", text: "Videos", icon: "video" },
  { to: "/admin/allquestions", text: "Questions", icon: "quiz" },
  { to: "/admin/quizes", text: "Quizes", icon: "quiz" },
  { to: "/admin/rewards", text: "Rewards", icon: "reward" },
  { to: "/admin/manage-price", text: "Price", icon: "dollar" },
];

const SalesSideBar = [
  { to: "/sales/user-tracking", text: "Track User", icon: "trackUser" },
  { to: "/sales/deposits", text: "Deposits", icon: "finance" },
]
const managerSideBar = [
  { to: "/admin/user-tracking", text: "Track User", icon: "trackUser" },
  { to: "/admin/deposits", text: "Deposits", icon: "finance" },
]
export default function Navbar({ setIsSidebarOpen }) {
  const [navItems, setNavItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { user, score, pseudo } = useUserRole();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsLoading(true);
      if (user) {
        try {
          const tokenResult = await getIdTokenResult(user);
          const claims = tokenResult.claims;
          if (claims?.isAdmin) {
            if(claims.role === "admin"){
              setNavItems(adminSidebar);
            } else if(claims.role === "manager") {
              setNavItems(managerSideBar)
            } else{
              setNavItems(SalesSideBar)
            }
          } else {
            setNavItems(userSideBar);
          }
        } catch (error) {
          console.error("Error getting token claims:", error);
        }
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  if (isLoading) {
    return <SidebarSkeleton />;
  }
  return (
    <div className="flex flex-col h-[100%]  min-h-[425px] w-[270px] transition-all duration-1000 border-r border-[rgba(144,157,172,0.2)] mdPlus:relative bg-[#0F28A4] md:bg-transparent  fixed left-0 top-0 z-20">
      <div
        className="p-6 border-b-[1px]"
        style={{
          borderImage:
            "linear-gradient(to right, rgba(144, 157, 172, 0), rgba(144, 157, 172, 1), rgba(144, 157, 172, 0)) 1",
        }}
      >
        <div className="flex items-center gap-3 p-2">
          <span>
            <img src={LogoImg} alt="" />
          </span>
          <span className="flex items-center">
            <img src={LogoText} alt="" className="hover:fill-green-500" />
          </span>
        </div>
      </div>

      <div className="flex-grow overflow-y-auto h-screen scrollbar-thin scrollbar-thumb-[#4268ff] scrollbar-track-[#113be1]/0 scrollbar-thumb-rounded-full  scrollbar-corner-[#113be1]/0">
        <div className="p-6">
          <div className="user-info mb-6">
            <NavUserInfo user={user} pseudo={pseudo} score={score} />
          </div>
          <nav className="NavLinks">
            <ul className="p-0">
              {navItems?.map(({ to, text, icon }) => (
                <li
                  key={to}
                  className="my-2.5"
                  onClick={() =>
                    window.innerWidth < 900 && setIsSidebarOpen(false)
                  }
                >
                  <NavLink
                    to={to}
                    className={({ isActive }) =>
                      `flex items-center gap-2.5 text-white text-left text-sm py-2 px-4 rounded-md transition-all duration-1000 ${
                        isActive
                          ? "bg-gradient-to-r from-[#113be1] via-[#4268ff] to-[#113be1]"
                          : "hover:bg-white/5"
                      } `
                    }
                  >
                    {({ isActive }) => (
                      <>
                        <span
                          className={`flex items-center justify-center w-8 h-8 rounded ${
                            isActive ? "bg-white" : "bg-[#000]"
                          } `}
                        >
                          <SvgIcon name={icon} isActive={isActive} />
                        </span>
                        <span className="flex items-center text-left">
                          {text}
                        </span>
                      </>
                    )}
                  </NavLink>
                </li>
              ))}
              <li className="my-2.5" onClick={() => handleLogout()}>
                <NavLink
                  to={""}
                  className={`flex items-center gap-2.5 text-white text-sm py-2 px-4 rounded-md transition-all duration-1000 hover:bg-white/5`}
                >
                  <span
                    className={`flex items-center justify-center w-8 h-8 rounded bg-[#000]
                                    }`}
                  >
                    <SvgIcon name={"logout"} />
                  </span>
                  Déconnexion
                </NavLink>
              </li>
              <li>
                <div className="mt-10 border-[rgba(144,157,172,1)]">
                  <DocumentationCard />
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
