import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../firebase/config";
import toast from "react-hot-toast";
import {
  getIdTokenResult,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import LogoImage from "../Images/LogoWithText.png";
import LoginBg from "../Images/LoginBg.png";
import { doc, setDoc } from "firebase/firestore";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [currentError, setCurrentError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleValidation = () => {
    setCurrentError("");

    if (!email) {
      setCurrentError("L'adresse mail est requis");
      return false;
    }

    if (!password) {
      setCurrentError("Le mot de passe est requis");
      return false;
    }

    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!handleValidation()) return;

    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const tokenResult = await getIdTokenResult(user);
      const claims = tokenResult?.claims;

      if (claims.isAdmin) {
        if(claims.role === "admin"){
          navigate("/admin/home");
        } else {
          navigate("/sales/user-tracking");
        }
      } else if (claims.payment) {
        const userRef = doc(db, "users", user.uid);
        navigate("/");
      } else {
        await signOut(auth);
        toast.remove();
        toast.error("Accès non autorisé, déconnexion..!", {
          position: "top-center",
          duration: 4000,
        });
      }
    } catch (error) {
      setCurrentError("Ton adresse e-mail ou ton mot de passe est incorrect");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="flex min-h-screen flex-col items-center bg-[#02081F]  bg-gradient-to-br from-blue-900 to-black"
      style={{ backgroundImage: `url(${LoginBg})` }}
    >
      <div className="mb-10 flex items-center gap-2 mt-10">
        <img src={LogoImage} width={180} height={130} alt="Logo" />
      </div>
      
      <div className="w-[90%] md:max-w-[600px] h-fit md:h-[580px] px-8 space-y-6 border border-gray-800 bg-[#121937] rounded-xl shadow-lg">
      {currentError && (
        <div className="flex h-[60px] p-4 mx-auto px-5 items-center gap-2.5 rounded-[15px] border-[1px] border-borderColor bg-[#FFE9E9] w-[250px] sm:w-fit absolute -top-18 left-[50%] -translate-x-[50%]">
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5356 12.65C13.78 12.8937 13.78 13.2938 13.5356 13.5375C13.2919 13.7813 12.8956 13.7813 12.6513 13.5375L10.0037 10.8875L7.33749 13.5562C7.09124 13.8 6.69252 13.8 6.44627 13.5562C6.20064 13.3062 6.20064 12.9063 6.44627 12.6625L9.11251 9.99374L6.465 7.35001C6.22062 7.10626 6.22062 6.70623 6.465 6.46248C6.70812 6.21873 7.10437 6.21873 7.34875 6.46248L9.99626 9.11247L12.6825 6.42502C12.9287 6.18127 13.3269 6.18127 13.5731 6.42502C13.8187 6.67502 13.8187 7.06873 13.5731 7.31873L10.8875 10.0063L13.5356 12.65ZM10 0C4.47688 0 0 4.475 0 10C0 15.525 4.47688 20 10 20C15.5231 20 20 15.525 20 10C20 4.475 15.5231 0 10 0Z"
                fill="#CE0500"
              />
            </svg>
          </div>
          <div>{currentError}</div>
        </div>
      )}
        <h1 className="text-3xl mt-6 font-bold text-center text-white">
          Bienvenue !
        </h1>
        <p className="text-center text-[16px] text-gray-400">
          Connecte-toi ou crée un compte pour accéder à ton espace.
        </p>
        <form onSubmit={onSubmit} className="flex flex-col gap-6">
          <div className="md:md:mx-[18px]">
            <label
              htmlFor="email"
              className="block text-[16px] text-start font-medium text-gray-300"
            >
              Adresse mail
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setCurrentError("");
              }}
              placeholder="email@domain.com"
              className="w-full focus:shadow-glow px-3 py-[10px] mt-1 text-gray-200 bg-[#2a304b] border border-transparent focus:border-blue-500 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="md:mx-[18px]">
            <label
              htmlFor="password"
              className="block text-[16px]  text-start font-medium text-gray-300"
            >
              Mot de passe
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setCurrentError("");
              }}
              placeholder="******"
              className="w-full focus:shadow-glow px-3 py-[10px] mt-1 text-gray-200 bg-[#2a304b] border border-transparent focus:border-blue-500 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex items-center md:mx-[18px]">
            <input
              type="checkbox"
              id="remember"
              name="remember"
              className="w-4 h-4 focus:shadow-glow text-blue-600 bg-gray-100 border-transparent focus:border-blue-500 rounded-full focus:ring-blue-500"
            />
            <label
              htmlFor="remember"
              className="ml-2 text-[16px]  text-start text-gray-300"
            >
              Rester connecté
            </label>
          </div>
          <div className="md:mx-[18px]">
            <button
              type="submit"
              className={`w-full  px-4 py-[10px] text-[16px]  text-center font-medium text-white bg-gradient-to-r from-[#2952F0] via-[#4268FF] to-[#113BE1] bg-blue-600 rounded-md shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500  ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={loading}
            >
               {loading ? "Connexion..." : "Connexion"}
            </button>
          </div>
        </form>
        <footer className="flex flex-col gap-12">
          <div>
            <Link to="/password/forgot" className="text-white underline">
              Mot de passe oublié ?
            </Link>
          </div>
          <div className="flex justify-center text-[16px]  text-gray-400 md:mx-[18px]">
            <p>Tu n'as pas de compte ? </p>
            <Link to="/signup" className="underline text-blue-600">
              Inscription
            </Link>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Login;
