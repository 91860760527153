import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SvgIcon } from "../Components/SvgIcon";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useGlobal } from "../context/GlobalContext";

export default function CourseNavItem({
  section,
  courseModules,
  colorStyle,
  isOpen,
  onClick,
}) {
  const { userProgress } = useGlobal();
  const [selectedItem, setSelectedItem] = useState(
    userProgress?.currentItem?.id
  );
  const [selectedModuleId, setSelectedModuleId] = useState(
    userProgress?.currentModule?.id
  );

  const handleModuleSelect = (id) => {
    setSelectedModuleId(selectedModuleId === id ? null : id);
  };
  const handleNavItemClick = (id) => {
    setSelectedItem(id);
  };

  return (
    <div>
      {isOpen ? (
        <div
          className="flex flex-col  gap-[11px]  rounded-[9.83px] bg-[#ffffff1a] border-[1px] border-custom-color px-5 py-6"
          style={{ "--level-color": colorStyle }}
        >
          <div className="flex flex-col gap-[11px]" onClick={onClick}>
            <div className="w-full flex justify-between items-start">
              <div
                className="flex items-center py-[3px] px-[10px] rounded-[45px] bg-[#ffffff1a] font-bold uppercase text-xs leading-normal text-custom-color border-[1px] border-custom-color"
                style={{ "--level-color": colorStyle }}
              >
                {section?.levelName}
              </div>
              <span className="cursor-pointer transition-all">
                <SvgIcon name={"vectorDown"} />
              </span>
            </div>
            <h3 className="text-left text-white cursor-pointer font-poppins leading-[22.4px] font-bold text-[16px]">
              {section?.title}
            </h3>
            <p className="text-left text-[#A0AEC0] text-[12px] leading-normal font-normal">
              {section?.description}
            </p>
          </div>
          <div className="w-full">
            {courseModules?.map((module) => (
              <div key={module.id} className="flex flex-col">
                <div
                  className="flex justify-between cursor-pointer items-center h-[38px] my-1 rounded-[5px] bg-[#4a5065] p-[10px]"
                  onClick={() => handleModuleSelect(module.id)}
                >
                  <span className="text-white text-left text-[12px]">
                    {module.title}
                  </span>
                  <RiArrowDropDownLine className="text-white h-6 w-6 cursor-pointer" />
                </div>

                {selectedModuleId === module.id && (
                  <ul className="flex flex-col gap-[8px] w-full">
                    {module?.content.length > 0 &&
                      module?.content.map((item) => (
                        <li
                          key={item.id}
                          className={`text-[#A0AEC0] flex justify-between gap-[6px] items-center leading-normal text-[14px] font-normal text-left font-poppins bg-[#13182A] p-[10px] rounded-[5px]
                          ${
                            selectedItem === item?.id &&
                            item?.status !== "locked"
                              ? "bg-[#000000]"
                              : "bg-[#13182A]"
                          }
                            `}
                          onClick={() =>
                            handleNavItemClick(
                              item.status === "locked" ? selectedItem : item.id
                            )
                          }
                        >
                          {item.status === "locked" ? (
                            <p className="flex justify-between w-full">
                              <div className="flex items-center gap-2 ">
                                {item.type === "video" ? (
                                  <>
                                    <SvgIcon name={"navItemVideo"} />
                                    <div className="text-[10px] md:text-[12px] font-light md:font-normal max-w-[300px] text-white leading-normal hover:bg-transparent hover:no-underline  whitespace-nowrap w-[150px] overflow-hidden text-ellipsis lg:whitespace-normal lg:w-full">
                                      {item.title}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <span>
                                      <SvgIcon name={"questionMark"} />
                                    </span>
                                    <div className="text-[10px] md:text-[12px] font-light md:font-normal max-w-[300px] text-white leading-normal hover:bg-transparent hover:no-underline  whitespace-nowrap w-[150px] overflow-hidden text-ellipsis lg:whitespace-normal lg:w-full">
                                      {item.title}
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="module-title-right">
                                {item.status === "locked" && (
                                  <span>
                                    <SvgIcon name={"lessonLock"} />
                                  </span>
                                )}
                              </div>
                            </p>
                          ) : (
                            <Link
                              to={`/resume/course-content/items/${item.id}`}
                              state={{
                                sectionName: section?.title,
                                moduleName: module.title,
                                id: item.id,
                              }}
                              className="flex justify-between w-full"
                            >
                              <div className="flex items-center gap-2 ">
                                {item.type === "video" ? (
                                  <>
                                    <SvgIcon name={"navItemVideo"} />

                                    <div className="text-[10px] md:text-[12px] font-light md:font-normal max-w-[300px] text-white leading-normal hover:bg-transparent hover:no-underline whitespace-nowrap w-[150px] overflow-hidden text-ellipsis lg:whitespace-normal lg:w-full">
                                      {item.title}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <span>
                                      <SvgIcon name={"questionMark"} />
                                    </span>
                                    <div className="text-[10px] md:text-[12px] font-light md:font-normal max-w-[300px] text-white leading-normal hover:bg-transparent hover:no-underline  whitespace-nowrap w-[150px] overflow-hidden text-ellipsis lg:whitespace-normal lg:w-full">
                                      {item.title}
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="module-title-right">
                                {item.status === "unlocked" && (
                                  <span>
                                    <SvgIcon name="sphere" />
                                  </span>
                                )}
                                {item.status === "completed" && (
                                  <span>
                                    <SvgIcon name={"tickMark"} />
                                  </span>
                                )}
                              </div>
                            </Link>
                          )}
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-[11px]">
          <div
            className="flex flex-col gap-[11px] items-center rounded-[9.83px] bg-[#ffffff1a] border-[1px] border-custom-color px-5 py-6"
            style={{ "--level-color": colorStyle }}
            onClick={onClick}
          >
            <div className="flex items-center gap-[10px]">
              <div
                className="flex items-center py-[3px] px-[10px] rounded-[45px] bg-[#ffffff1a] font-bold uppercase text-xs leading-normal text-custom-color border-[1px] border-custom-color"
                style={{ "--level-color": colorStyle }}
              >
                {section?.levelName}
              </div>
              <span className="cursor-pointer transition-all">
                <SvgIcon name={"vectorRight"} />
              </span>
            </div>
            <h3 className="text-left cursor-pointer text-white font-poppins leading-[22.4px] font-bold text-[16px]">
              {section?.title}
            </h3>
            <p className="text-left text-[#A0AEC0] text-[12px] leading-normal font-normal">
              {section?.description}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
