/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

import { getFunctions, httpsCallable } from "firebase/functions";
import toast from "react-hot-toast";

import SignupBg from "../Images/SignupBg.png";
import logo from "../Images/LogoWithText.png";
import { Loader2 } from "lucide-react";
import { getBrowserInformation } from "../utils/getBrowserInfo";
import { calculateTimeLeft } from "../utils/calculateTimeLeft";

const ENV = process.env.REACT_APP_ENV;

const UserInfoForm = ({
  onNext,
  register,
  errors,
  password,
  isValid,
  isLoading,
}) => (
  <form className="flex flex-col gap-4 mb-2 font-poppins">
    <div className="grid grid-cols-2 gap-4">
      <div>
        <label
          htmlFor="prénom"
          className="block text-start text-sm text-gray-300 mb-1"
        >
          Prénom
        </label>
        <input
          id="prénom"
          type="text"
          {...register("firstName", { required: "Le prénom est requis" })}
          className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
          placeholder="Votre prénom"
        />
        {errors.firstName && (
          <p className="text-red-500 text-sm mt-1">
            {errors.firstName.message}
          </p>
        )}
      </div>
      <div>
        <label
          htmlFor="nom"
          className="block text-start text-sm text-white mb-1"
        >
          Nom
        </label>
        <input
          id="nom"
          type="text"
          {...register("lastName", { required: "Le nom est requis" })}
          className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
          placeholder="Votre nom"
        />
        {errors.lastName && (
          <p className="text-red-500 text-sm mt-1">{errors.lastName.message}</p>
        )}
      </div>
    </div>
    <div>
      <label
        htmlFor="email"
        className="block text-start text-sm text-gray-300 mb-1"
      >
        Adresse mail
      </label>
      <input
        id="email"
        type="email"
        placeholder="email@domain.com"
        {...register("email", {
          required: "L'email est obligatoire",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Adresse email invalide",
          },
        })}
        disabled
        className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
      />
      {errors.email && (
        <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
      )}
    </div>
    <div>
      <label
        htmlFor="password"
        className="block text-start text-sm text-gray-300 mb-1"
      >
        Mot de passe
      </label>
      <input
        id="password"
        type="password"
        placeholder="******"
        {...register("password", {
          required: "Le mot de passe est obligatoire",
          minLength: {
            value: 6,
            message: "Le mot de passe doit comporter au moins 6 caractères",
          },
        })}
        className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
      />
      {errors.password && (
        <p className="text-red-500 text-sm mt-1">{errors.password.message}</p>
      )}
    </div>
    <div>
      <label
        htmlFor="confirmPassword"
        className="block text-start text-sm text-gray-300 mb-1"
      >
        Confirmer le mot de passe
      </label>
      <input
        id="confirmPassword"
        type="password"
        placeholder="******"
        {...register("confirmPassword", {
          required: "La confirmation du mot de passe est obligatoire",
          validate: (value) =>
            value === password || "Les mots de passe ne correspondent pas",
        })}
        className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
      />
      {errors.confirmPassword && (
        <p className="text-red-500 text-sm mt-1">
          {errors.confirmPassword.message}
        </p>
      )}
    </div>
    <div>
      <label
        htmlFor="phone"
        className="block text-start text-sm text-gray-300 mb-1"
      >
        Numéro de téléphone (optionnel)
      </label>
      <input
        id="phone"
        type="tel"
        placeholder="+33 XX XX XX XX"
        {...register("phoneNumber")}
        className="w-full focus:shadow-glow px-4 py-3 bg-[#FFFFFF1A] border border-[#FFFFFF1A] rounded-lg text-white focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
      />
    </div>
    <button
      onClick={onNext}
      disabled={!isValid || isLoading}
      className={`
        w-full py-3 px-4 mt-4
        border border-transparent rounded-md 
        shadow-sm text-sm font-medium text-white 
        bg-gradient-to-r from-[#2952F0] via-[#4268FF] to-[#113BE1]
        bg-indigo-600 hover:bg-indigo-700 
        focus:outline-none focus:ring-4 focus:ring-indigo-500/50 
        transition-all duration-300
        ${isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-indigo-700"}
      `}
    >
      {isLoading ? (
        <div className="flex items-center  justify-center">
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Chargement...
        </div>
      ) : (
        "Enregistrez votre compte"
      )}
    </button>
  </form>
);
const CheckoutStripeUserData = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [email, setEmail] = useState("");
  const { id } = useParams();
  const browserInfo = getBrowserInformation();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors, isValid },
    watch,
    trigger,
    reset,
  } = useForm({ mode: "onChange" });

  const watchedFields = watch();
  const password = watch("password");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get("id");

  const getUserEmail = async () => {
    const functions = getFunctions();
    const getUserEmailForPaypal = httpsCallable(
      functions,
      "getUserEmailForPaypal"
    );

    try {
      const res = await getUserEmailForPaypal({ orderId });
      const userEmail = res.data.email;
      setEmail(userEmail);
      console.log("Response from getUserEmailForPaypal:", res.data);
      console.log("Setting email to:", userEmail);
      // Reset the entire form with the new email
      reset({
        email: userEmail,
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
      });
    } catch (error) {
      console.error("Error fetching email:", error);
      toast.error("Erreur lors de la récupération de l'email");
    }
  };
  useEffect(() => {
    if (orderId) {
      getUserEmail();
    }
  }, [orderId]);
  useEffect(() => {
    console.log("Current form values:", watch());
  }, [watch()]);

  const handleConfirmStripeSubscription = async (e) => {
    e.preventDefault()

    setIsSubmitting(true);
    const isValid = await trigger();
    if (!isValid) {
      setIsSubmitting(false);
      return;
    }
    try {
      const userData = {
        firstName: watchedFields.firstName,
        lastName: watchedFields.lastName,
        email: watchedFields.email,
        password: watchedFields.password,
        phoneNumber: watchedFields.phoneNumber || "",
        displayName: `${watchedFields.firstName} ${watchedFields.lastName}`,
      };
      setIsSubmitting(true);
      const functions = getFunctions();
      const confirmCheckoutStripeSubscription = httpsCallable(
        functions,
        "confirmCheckoutStripeSubscription"
      );

      const result = await confirmCheckoutStripeSubscription({
        orderId,
        userData,
      });

      if (result.data.success) {
        setIsSubmitting(false);
        toast.success("Inscription réussie!");
        navigate("/payment-success");
      } else {
        throw new Error(result.data.error || "Une erreur est survenue");
      }
    } catch (error) {
      toast.error(
        error.message || "Erreur lors de l'inscription. Veuillez réessayer."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="min-h-screen h-fit w-full flex flex-col bg-[#00061E] bg-cover bg-no-repeat pt-6 items-center"
      style={{ backgroundImage: `url(${SignupBg})` }}
    >
      <div className="flex items-center gap-2">
        <img src={logo} width={140} height={130} alt="Logo" />
        {ENV === "staging" && (
          <div className="text-sm text-gray-500">{ENV}</div>
        )}
      </div>

      <div className="w-[90%] lg:w-[85%] py-4">
        <div className="flex flex-col items-center gap-10 min-w-[100%]">
          <div className="w-full md:w-[75%] lg:w-[45%] px-2 h-full">
            <div className="">
              <div className="rounded-lg bg-[#ffffff12] pt-4 form-card">
                <div className="p-3 md:px-10 py-6 ">
                  <div className="flex items-center gap-2 mb-6">
                    <h1 className="text-start font-bold text-2xl text-gray-300">
                      Informations personnelles
                    </h1>
                  </div>
                  <UserInfoForm
                    isValid={isValid}
                    onNext={handleConfirmStripeSubscription}
                    register={register}
                    errors={errors}
                    password={password}
                    isLoading={isSubmitting}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutStripeUserData;
