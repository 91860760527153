import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  Timestamp,
  onSnapshot,
  orderBy,
  getCountFromServer,
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/config";
import { Edit, Plus, Search, Trash2 } from "lucide-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import toast from "react-hot-toast";

// Stat Card Component with themed colors
const StatCard = ({
  icon: Icon,
  title,
  value,
  iconColor = "bg-primaryBlue/20",
  textColor = "text-primaryBlue",
}) => (
  <div className="bg-white/10 backdrop-blur-sm rounded-lg border border-borderColor p-6 hover:shadow-lg transition-shadow duration-200">
    <div className="flex items-center space-x-4">
      <div className={`p-3 rounded-full ${iconColor}`}>
        <Icon className={`w-6 h-6 ${textColor}`} />
      </div>
      <div>
        <p className="text-sm font-medium text-white/60">{title}</p>
        <h3 className="text-2xl text-left font-bold text-white">{value}</h3>
      </div>
    </div>
  </div>
);

// Pagination Component
const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="flex items-center justify-center px-6 py-4 bg-[#071036] border-t border-borderColor">
    <button
      onClick={() => onPageChange(currentPage - 1)}
      disabled={currentPage === 1}
      className="px-4 py-2 text-xl font-medium text-white/60 disabled:opacity-50 disabled:cursor-not-allowed hover:text-white transition-colors"
    >
      &lt;
    </button>
    <span className="text-sm text-white/60">
      Page {currentPage} of {totalPages}
    </span>
    <button
      onClick={() => onPageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
      className="px-4 py-2 text-xl font-medium text-white/60 disabled:opacity-50 disabled:cursor-not-allowed hover:text-white transition-colors"
    >
      &gt;
    </button>
  </div>
);

// Main Dashboard Component
const UsersTracking = () => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalAttempts: 0,
    unpurchasedAttempts: 0,
    last15DaysAttempts: 0,
    conversionPercentage: 0,
  });
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [note, setNote] = useState("");
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const recordsPerPage = 8;

  useEffect(() => {
    var unsubscribe;
    const fetchDashboardData = async () => {
      try {
        setLoading(true);

        const fifteenDaysAgo = Timestamp.fromDate(
          new Date(Date.now() - 15 * 24 * 60 * 60 * 1000)
        );

        const totalCount = (
          await getCountFromServer(query(collection(db, "userCheckout")))
        ).data().count;
        const unpurchaseAttempt = (
          await getCountFromServer(
            query(
              collection(db, "userCheckout"),
              where("purchase", "==", false)
            )
          )
        ).data().count;

        const q = query(
          collection(db, "userCheckout"),
          orderBy("timestamp", "desc")
        );
        unsubscribe = onSnapshot(q, (querySnapshot) => {
          const checkoutData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const userData = checkoutData.map((checkout) => ({
            id: checkout.id,
            name: checkout.displayName || "Unknown",
            email: checkout.email || "N/A",
            phoneNumber: checkout.phoneNumber || "N/A",
            remindersSent: checkout.remindersSent || false,
            purchase: checkout.purchase || false,
            timestamp: checkout.timestamp || null,
            note: checkout.note || null
          }));

          const totalAttempts = totalCount;
          const unpurchasedAttempts = unpurchaseAttempt;
          const last15DaysAttempts = userData.filter(
            (user) =>
              user.timestamp &&
              user.timestamp.toMillis() > fifteenDaysAgo.toMillis()
          ).length;
          const conversionPercentage =
            totalAttempts > 0
              ? Math.round(
                  (100 * (totalAttempts - unpurchasedAttempts)) / totalAttempts
                )
              : 0;

          setStats({
            totalAttempts,
            unpurchasedAttempts,
            last15DaysAttempts,
            conversionPercentage,
          });

          setUsers(userData);
          setLoading(false);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchDashboardData();

    return () => unsubscribe();
  }, []);

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredUsers.length / recordsPerPage);
  const startIndex = (currentPage - 1) * recordsPerPage;
  const paginatedUsers = filteredUsers.slice(
    startIndex,
    startIndex + recordsPerPage
  );
  console.log("paginatedUsers: ", paginatedUsers);
  const handlePageChange = (newPage) => setCurrentPage(newPage);

  const handleNotes = (user) => {
    setNote("")
    setSelectedUser(user);
    setShowNoteModal(true);
    if(user.note){
      setNote(user.note)
    }
  };
  const handleAddNote = async () => {
    if (!selectedUser.id || !note) {
      toast.error("Required fields are missing...");
      return;
    }
    try {
      const userDoc = doc(db, "userCheckout", selectedUser.id);
      await setDoc(userDoc, { note }, { merge: true }).then(() => {
        toast.success("Notes added successfully...");
      });
      setShowNoteModal(false);
      setNote("");
    } catch (error) {
      console.error("Error saving note:", error);
    }
  };
  const handleDeleteNote = async(id) => {
    await updateDoc(doc(db, "userCheckout", id), {
      note: ""
    })
    
  };

  if (loading) {
    return (
      <div className="rounded-xl border-[#ffffff55] border h-screen flex justify-center items-center bg-primary p-6 font-poppins m-10">
        <div className="animate-spin w-16 h-16 border-4 border-primaryBlue border-t-transparent rounded-full" />
      </div>
    );
  }

  return (
    <div className="rounded-xl border-[#ffffff55] border bg-primary p-6 font-poppins m-10">
      <div className="max-w-7xl mx-auto space-y-6">
        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <StatCard
            icon={(props) => (
              <svg
                {...props}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                />
              </svg>
            )}
            title="Total Attempts"
            value={stats.totalAttempts}
            iconColor="bg-purple-500/20"
            textColor="text-purple-500"
          />
          <StatCard
            icon={(props) => (
              <svg
                {...props}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                />
              </svg>
            )}
            title="Unpurchased Attempts"
            value={stats.unpurchasedAttempts}
            iconColor="bg-red-500/20"
            textColor="text-red-500"
          />
          <StatCard
            icon={(props) => (
              <svg
                {...props}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            )}
            title="Last 15 Days Attempts"
            value={stats.last15DaysAttempts}
            iconColor="bg-yellow-500/20"
            textColor="text-yellow-500"
          />
          <StatCard
            icon={(props) => (
              <svg
                {...props}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            )}
            title="Conversion %"
            value={`${stats.conversionPercentage}%`}
            iconColor="bg-green-500/20"
            textColor="text-green-500"
          />
        </div>

        {/* Users Table */}
        <div className="bg-[#071036] rounded-lg border border-borderColor overflow-hidden">
          <div className="p-6 border-b header-container border-borderColor">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold text-white">
                User Attempts Details
              </h2>
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setCurrentPage(1);
                  }}
                  className="pl-10 pr-4 py-2 bg-white/5 border border-borderColor rounded-lg text-white placeholder-white/40 focus:outline-none focus:ring-2 focus:ring-primaryBlue/50"
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white/40 w-4 h-4" />
              </div>
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-white/5">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                    Phone Number
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                    Note Actions
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                    Notes
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                    Reminder Sent
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                    Purchase Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white/60 uppercase tracking-wider">
                    Date Attempt
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-borderColor">
                {paginatedUsers.map((user) => (
                  <tr key={user.id} className="hover:bg-white/5">
                    <td className="px-6 py-4 text-left whitespace-nowrap">
                      <div className="text-sm font-medium text-white">
                        {user.name}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-left whitespace-nowrap">
                      <div className="text-sm text-white/60">{user.email}</div>
                    </td>
                    <td className="px-6 py-4 text-left whitespace-nowrap">
                      <div className="text-sm text-white/60">
                        {user.phoneNumber || "N/A"}
                      </div>
                    </td>
                    <td className="px-6 flex gap-2 py-4 text-left whitespace-nowrap">
                      {/* <div  className="bg-gray-200 p-1 rounded-full cursor-pointer" onClick={() =>handleEdit()} >
                        <Edit color="orange" size={16} />
                      </div> */}
                      <div
                        className="bg-green-200 p-1 rounded-full cursor-pointer"
                        onClick={() => handleNotes(user)}
                      >
                        <Plus color="green" size={16} />
                      </div>
                      <div
                        className="bg-red-200 p-1 rounded-full cursor-pointer"
                        onClick={() => handleDeleteNote(user.id)}
                      >
                        <Trash2 color="red" size={16} />
                      </div>
                    </td>
                    <td className="px-6 py-4 text-left whitespace-nowrap">
                      <div className="text-sm text-white/60" dangerouslySetInnerHTML={{ __html: user?.note}}>
                        
                      </div>
                    </td>
                    <td className="px-6 py-4 text-left whitespace-nowrap">
                      <div className="text-sm text-white/60">
                        {
                          Object.values(user?.remindersSent).filter(
                            (value) => value
                          ).length
                        }
                      </div>
                    </td>
                    <td className="px-6 py-4 text-left whitespace-nowrap">
                      <div
                        className={`text-sm font-medium ${
                          user.purchase ? "text-green-500" : "text-red-500"
                        }`}
                      >
                        {user.purchase ? "True" : "False"}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-left whitespace-nowrap">
                      <div className={`text-sm font-medium text-white`}>
                        {new Date(user.timestamp?.toDate()).toDateString()}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      {showNoteModal && (
        <div className="fixed w-full h-full inset-0 bg-black/50 flex justify-center items-center">
          <div className="bg-[#060B26] border w-[500px] border-gray-500 p-2 py-4 rounded-lg">
            <h3 className="text-lg font-bold mb-4">Add Note</h3>
            <ReactQuill
              value={note}
              onChange={setNote}
              theme="snow"
              className=" "
              modules={{
                toolbar: [
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link"],
                  ["clean"],
                ],
              }}
            />
            <div className="flex justify-end space-x-4 mt-4">
              <button
                onClick={() => setShowNoteModal(false)}
                className="px-4 py-2 bg-gray-300 text-black rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={() => handleAddNote()}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
              >
                {selectedUser.note ? "Update": "Add"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersTracking;
