import React from "react";

const PriceHeaderSkeleton = () => {
  return (
    <div className="text-start bg-slate-700 p-4 rounded-lg mb-10 flex justify-between">
      <div className="w-48 h-8 bg-gray-500 rounded animate-pulse" />
      <div className="w-24 h-8 bg-gray-500 rounded animate-pulse" />
    </div>
  );
};

export default PriceHeaderSkeleton;
