import React, {useState } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../Images/Logo.svg";
import SuccessMark from "../Images/payment-success.png";
import SignupBg from "../Images/SignupBg.png";
const ENV = process.env.REACT_APP_ENV;

const CheckoutFiftySuccess = () => {

  return (
    <div
      className="min-h-screen h-fit w-full flex flex-col bg-[#00061E] bg-cover bg-no-repeat pt-10 items-center"
      style={{ backgroundImage: `url(${SignupBg})` }}
    >
      <div className="flex items-center gap-2">
        <img src={Logo} width={80} height={80} alt="Logo" />
        <div className="flex flex-col text-white font-normal">
          <span>ChatGPT</span>
          <span>Academy</span>
        </div>
      </div>
      <div className="w-full flex mt-32 flex-col items-center justify-center p-10 text-white">
        <div className="text-center mb-8">
          <img
            className="mx-auto w-[85px] h-[85px] mb-4"
            src={SuccessMark}
            alt="check"
          />
          <div>
            <h2 className="text-2xl font-bold text-[#ffffff] mb-4">
              Votre acompte a été traité avec succès !{" "}
            </h2>
            <p className="text-[#fff] text-center ">
              Merci pour votre confiance ! Un email de confirmation vient de
              vous être envoyé.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutFiftySuccess;
