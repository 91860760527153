import React, { useState } from 'react';
import Modal from './Modal';
import PriceForm from './PriceForm';

const PriceCard = ({ priceData, fetchPriceData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleFormSubmit = (updatedData) => {
    closeModal();
  };

  return (
    <div className="bg-[#05063b] border border-white bottom-1 text-white w-[70%] mt-10 p-6 flex flex-col gap-10 rounded-lg ">
      <div>
        <h2 className="font-normal text-left text-white text-2xl">Formation Chatgpt Academy</h2>
        <p className="text-left text-white font-normal text-2xl">
          {priceData.originalPrice ? `${priceData.originalPrice}` : '999,00 '}
        </p>
      </div>

      <div>
        <p className="text-left text-white text-2xl">🚨 OFFRE LIMITEE (-{priceData.discountPercentage || '52'}%)</p>
        <p className="text-left text-white font-normal text-2xl">
          {priceData.discountedPrice ? `${priceData.discountedPrice} ` : '487.00'}
        </p>
      </div>
      <div className="flex justify-between items-center ">
        <p className="text-left text-white font-bold text-lg">Chatgpt Academy <br /> <span className="text-sm font-normal">Formation IA</span> </p>
        <p className=" font-bold text-left text-white text-lg">{priceData.discountedPrice ? `${priceData.discountedPrice}` : '487.00'}</p>
      </div>

      <div className="w-full h-[1px] text-white bg-white"></div>
      <div className="calPrice w-full">
        {/* Subtotal and Taxes */}
        <div className="flex flex-col gap-6">
          <div className="flex justify-between items-center">
            <p className="text-left text-white text-lg">Sous-total</p>
            <p className="text-left text-white text-lg">
              {priceData.discountedPrice ? `${priceData.discountedPrice}` : '487.00 '}
            </p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-left text-white text-lg">Taxe</p>
            <p className="text-left text-white text-lg">
              {priceData.taxPercentage ? `${(priceData.discountedPrice * priceData.taxPercentage / 100).toFixed(2)} ` : '0,00 '}
            </p>
          </div>
        </div>

        <div className="w-full h-[1px] my-4 bg-white"></div>

        {/* Total Amount */}
        <div className="flex justify-between items-center">
          <p className="text-left text-white text-lg">Montant total du</p>
          <p className="text-left text-white text-lg">
            {priceData.totalPrice ? `${priceData.totalPrice} ` : priceData.discountedPrice ? `${priceData.discountedPrice} ` : '487,00 '}
          </p>
        </div>
      </div>
      {/* Update Price Button */}
      <button
        onClick={openModal}
        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
      >
        Update Price
      </button>

      {/* Modal for updating price */}
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <PriceForm
            initialData={priceData} 
            onSubmit={handleFormSubmit}
            fetchPrice={fetchPriceData} 
          />
        </Modal>
      )}
    </div>
  );
};

export default PriceCard;
