import React, { useState } from 'react';
import toast from 'react-hot-toast';

const PaymentOptions = ({ onMethodChange }) => {
  const [selectedMethod, setSelectedMethod] = useState('stripe');
const url = window.location.pathname
console.log(url)
  const handleMethodChange = (method) => {
    setSelectedMethod(method);
    onMethodChange(method);
  };

 
  return (
    <div className="my-4 flex gap-4 items-center  text-gray-300 text-sm">
          <select
            value={selectedMethod}
            onChange={(e) => handleMethodChange(e.target.value)}
            className="w-full px-4 py-3 bg-[#161B2F] border border-gray-700 rounded-lg text-white"
          >
            <option value="stripe" className='bg-[#161B2F] text-white'>Carte bancaire</option>
            <option  value="paypal" className='bg-[#161B2F] text-white'>PayPal (Paiement en 4 fois)</option>
            <option  disabled value="googlepay" className='bg-[#161B2F] text-white'>Apple Pay</option>
          </select>
    </div>
  );
};



export const PaymentButtonRadio = ({ onMethodChange, email }) => {
  const [selectedMethod, setSelectedMethod] = useState('stripe');
  const url = window.location.pathname
  const handleMethodChange = (method) => {
    if(!email && url !== "/signup"){
      toast.error("L'e-mail est requis...")
      return
    }
    setSelectedMethod(method);
    onMethodChange(method);
  };

  return (
    <div className="my-5 flex flex-col md:flex-row md:gap-20 justify-center md:items-center  text-gray-300">
      <label className="flex items-center space-x-2 p-3 rounded-lg  cursor-pointer">
        <input
          type="radio"
          name="payment"
          value="stripe"
          checked={selectedMethod === 'stripe'}
          onChange={(e) => handleMethodChange(e.target.value)}
          className="text-blue-600 bg-gray-700 w-[22px] h-[22px] border-gray-600 focus:ring-blue-500 focus:ring-offset-gray-800"
        />
        <span className="text-lg font-bold text-gray-300">Paiement en 1 fois</span>
      </label>

      <label className="flex items-center space-x-2 p-3 rounded-lg  cursor-pointer">
        <input
          type="radio"
          name="payment"
          value="paypal"
          checked={selectedMethod === 'paypal'}
          onChange={(e) => handleMethodChange(e.target.value)}
          className="text-blue-600 w-[22px] h-[22px] bg-gray-700 border-gray-600 focus:ring-blue-500 focus:ring-offset-gray-800"
        />
        <span className="text-lg font-bold text-gray-300">Paiement en 4 fois</span>
      </label>

    </div>
  );
};


export default PaymentOptions;